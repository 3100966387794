<template>
        <d-col lg="3">
            <h5  class="provider-page__title">{{convertServiceLocationTypeToString(service.parking_type_id)}}
               <span v-if="service.covered">{{ $t("priceList.covered") }}</span> <span v-else>{{ $t("priceList.uncovered") }}</span>
               <input v-model="pricePerService.active" type="checkbox" class="ml-2"> </h5>
              <small class="text-danger" v-if="!pricePerService.active && !nonbookable"> {{ $t("priceList.updatePriceList") }}</small>
           <div v-if="!nonbookable">
              <template v-for="(arr, index) in pricePerService.priceValues" >
              <div :key="index" class="input-group input-group-sm mb-3">
                <div class="input-group-prepend">
                 
                  <span style="min-width: 4.5rem;" class="input-group-text" id="inputGroup-sizing-sm" >
                    <span class="font-weight-bold" v-if="pricePerService.priceValues.length-1==index">{{$t('priceList.extra')}}</span>
                    
                    <span v-else>  {{index+1}} 
                      <span v-if="index>=1">
                        {{ $t('priceList.days') }} {{ currencyIcon(currency) }}
                      </span>
                      <span v-else>
                        {{ $t('priceList.day') }} {{ currencyIcon(currency) }}
                      </span>
                    </span>
                  </span> 
                </div>
                
                <input :append="currencyIcon(currency)" :disabled="!pricePerService.active" class="form-control h-auto" :class="(pricePerService.priceValues[index]==0 && zeroError && pricePerService.active ) && 'border-danger'" v-model="pricePerService.priceValues[index]"  aria-label="Small" aria-describedby="inputGroup-sizing-sm">
              </div>
            </template>
          </div>
          </d-col>
</template>
<script>
  import Vue from "vue";
  export default {
    name: "PriceList",
    props: {
      service: {
        required: true,
        type: Object,
        
      },
      currency: String,
      serviceData : Object,
      zeroError: Boolean,
      nonbookable: Boolean
      
     
    },

    data: function () {
      return {
        bus: new Vue(),
        pricePerService: this.serviceData,
    
      }
    },


    methods: {
      currencyIcon(currencyName){
       let currency
        switch (currencyName) {
          case "USD":
           currency = "$"
          break;
          case "CHF":
           currency = "CHF"
          break;
          case "GBP":
           currency = "£"
          break;
          case "AUD":
           currency = "$"
          break;
          default:
            currency = "€"
            break;
          }
          return currency
      },
      convertServiceLocationTypeToString: function (id) {
        let tempName
        
        if(id==="6dvgln4ofde"){
          tempName = this.$t("priceList.shuttle") 

        }
        if(id==="8ftpe6gpdvi"){
          tempName =  this.$t("priceList.valet") 

        }
        if(id==="hg49xy82wi"){
          tempName = "Park & Walk"

        }
        if(id==="lqbmkqreh"){
          tempName = "Park & Taxi"
        }
        return tempName
      },
     


    },

    mounted() {
      this.serviceLocationData = this.service  
    },

  
   
  }
</script>
