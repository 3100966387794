<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" x="0px" y="0px" fill="currentColor">
    <path d="M26,9h1a1,1,0,0,0,0-2H22V6a3,3,0,0,0-3-3H13a3,3,0,0,0-3,3V7H5.466a1,1,0,0,0,0,2H6V24a5.006,5.006,0,0,0,5,5H21a5.006,5.006,0,0,0,5-5ZM12,6a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1V7H12ZM24,24a3,3,0,0,1-3,3H11a3,3,0,0,1-3-3V9H24Z"></path>
    <path d="M13,13a1,1,0,0,0-1,1v8a1,1,0,0,0,2,0V14A1,1,0,0,0,13,13Z"></path>
    <path d="M19,13a1,1,0,0,0-1,1v8a1,1,0,0,0,2,0V14A1,1,0,0,0,19,13Z"></path>
  </svg>
</template>

<script>
export default {
  name: "IconDelete"
}
</script>
