<template>
  <div>
    <d-alert v-for="(error, index) in errors"
             :key="index"
            dismissible
             :show="error.timeUntilDismissed"
             :theme="error.type"
             @alert-dismissed="error.timeUntilDismissed = 0"
             @alert-dismiss-countdown="handleTimeChange">
      <b>{{error.message}}</b>
    </d-alert>
  </div>

</template>

<script>
	export default {
		name: "Feedback",

    props: {
      bus:{
    type: Object
  }
    },

    data () {
      return {

        errors: [],

        type: "success",
        message: '',
        duration: 3,
        timeUntilDismissed: 0
      }
    },

    mounted() {
      this.bus.$on('submitData', this.showMessage);
    },

    methods: {
      handleTimeChange (time) {
        this.timeUntilDismissed = time
      },

      showMessage ({type = 'success', message = 'TODO: set message variable', time = 3}) {
        this.errors.push({
          type: type,
          message: message,
          duration: time,
          timeUntilDismissed: this.duration
        })
      }
    },
	}
</script>
